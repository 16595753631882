import * as React from 'react';
import {useDispatch} from "react-redux";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { footerAction } from '../../store';
import { CATEGORY, CATEGORY_MAP, product  } from '../../config/configuration';
import { isDisabled } from '@testing-library/user-event/dist/utils';


const drawerWidth = 200;
const marginTop = 140;
const scrollToTop = window;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function OverviewComponent(props: Props) {

    const dispatch = useDispatch();

    dispatch(footerAction.setShowProducts());

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams()

    const tmpProdList: product[] = [];
    const [products, setProducts] = React.useState(tmpProdList);

    let subCatgFilter: string[] = [];
    const [subCategory, setSubCategory] = React.useState(subCatgFilter);

    let subCatgFilterList: string[] = [];
    const [subCategoryFilterList, setSubCategoryFilterList] = React.useState(subCatgFilterList);

    let tmpTypeFilterSet = new Set<string>();
    const [typeFilterSet, setTypeFilterSet] = React.useState(tmpTypeFilterSet);

    let typeFilter: string[] = [];
    const [type, setType] = React.useState(typeFilter);

    const theme = useTheme();
    const extraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const extraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function onFilterChange(category: any, subcategory: any, item: any ) {
      let buildUrl = `?category=${category}&subcategory=${subcategory}&item=${item}`
      navigate(`/products${buildUrl}`);
    }

    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      border: 'none',
      boxShadow: 24,
      p: 2,
      borderRadius: '8px'
    };

    const drawer = (
        <div>
          <Toolbar />
          
          <Typography style={{paddingBottom: 8, fontSize: '1.1rem', fontFamily: 'Lucida Handwriting', color: '#626262',
          letterSpacing: '1px', textAlign: 'center' }}> 
               <strong> Filters </strong> 
          </Typography>
          <Divider/>
          <FormControl fullWidth sx={{paddingY: 2, alignItems: 'center' }} component="fieldset" variant="standard">
            <FormLabel > 
              <Typography style={{fontSize: '1rem', fontFamily: 'Lucida Handwriting', letterSpacing: '1px', color: '#626262'}}> 
               <strong> Metals </strong> 
              </Typography>
            </FormLabel>
            <FormGroup>
              {
                subCategoryFilterList.map((item,index)=>{
                  return <FormControlLabel control={<Checkbox size="small" />} 
                  label={<Typography style={{fontSize: '0.9rem', fontFamily: 'Lucida Handwriting', letterSpacing: '1px', color: '#626262'}}>
                    {item}</Typography>} 
                  key={index} 
                  checked={subCategory.includes(item)} 
                  onChange={() => {
                    subCategory.includes(item) ? subCategory.splice(subCategory.findIndex(data => data == item), 1) : subCategory.push(item)
                    setSubCategory(subCategory);
                    onFilterChange(searchParams.get('category'), subCategory.join(","), searchParams.get('item'))
                  }}
                  disabled={subCategory.includes(item) && subCategory.length == 1}
                  />
                })
              }
            </FormGroup>
          </FormControl>

          <Divider />

          <FormControl fullWidth sx={{ paddingY: 2, alignItems: 'center' }} component="fieldset" variant="standard">
            <FormLabel>
              <Typography style={{fontSize: '1rem', fontFamily: 'Lucida Handwriting', letterSpacing: '1px', color: '#626262'}}> 
               <strong> Ornaments </strong> 
              </Typography>   
            </FormLabel>
            <FormGroup>
              {
                Array.from(typeFilterSet.values()).map( (item, key) => {
                  return <FormControlLabel control={<Checkbox size="small" />} 
                  label={<Typography style={{fontSize: '0.9rem', fontFamily: 'Lucida Handwriting', letterSpacing: '1px', color: '#626262'}}>
                    {item}</Typography>}
                  key={key} 
                  checked={type.includes(item)} 
                  onChange={() => {
                    type.includes(item) ? type.splice(type.findIndex(data => data == item), 1) : type.push(item)
                    setType(typeFilter)
                    onFilterChange(searchParams.get('category'), searchParams.get('subcategory'), type.join(","))
                  }}
                  disabled={type.includes(item) && type.length == 1}
                  />
                })
              }
            </FormGroup>
          </FormControl>
          <Divider />
        </div>
      );

    const container = window !== undefined ? () => window().document.body : undefined;

    React.useEffect(() => {
      
      // validate if query params exist else return 404 page not found
      if(searchParams.get('category') == null || searchParams.get('subcategory') == null || searchParams.get('item') == null || 
      searchParams.get('category') == "" || searchParams.get('subcategory') == "" || searchParams.get('item') == ""
      ) {
        navigate('/pagenotfound')
        console.log("page not found")
      } else {
        // Update the document title using the browser API
      console.log('category: ', searchParams.get('category'));
      console.log('subcategory: ', searchParams.get('subcategory'));
      console.log('items: ', searchParams.get('item'));

      const categoryQueryParam = searchParams.get('category');
      let category: string = "";
      
      
      for (const catg in CATEGORY) {
        if(catg == categoryQueryParam) {
          category = catg;
          break;
        }
      }

      if(category != "") {

        const subCategoryMap = CATEGORY_MAP.get(category);

        console.log('subCategoryMap: ', subCategoryMap);

        subCatgFilterList = [];
        tmpTypeFilterSet.clear();
        subCategoryMap!.forEach((key,value) => {
          subCatgFilterList.push(value);
        });

        setSubCategoryFilterList(subCatgFilterList);
        setTypeFilterSet(tmpTypeFilterSet);

        const subCatgList = searchParams.get('subcategory')!.split(',');

        // verify if the sub category list is valid
        let subCatgListIsValid = true;
        subCatgList.forEach((item) => {
          if(!subCatgFilterList.includes(item)) {
            navigate(`/products/notfound`);
            subCatgListIsValid = false;
          }
        });

        if (subCatgListIsValid) {
          subCatgFilter = [];
          subCatgList!.forEach( subCatg => {
            subCatgFilter.push(subCatg);

            let tmpType = subCategoryMap!.get(subCatg);
            tmpType!.forEach( (key, value) => {
              tmpTypeFilterSet.add(value);
            })
            
          }); 

          setSubCategory(subCatgFilter);

          console.log('subCatgList: ', subCatgList);

          const itemsList = searchParams.get('item')!.split(',');

          // verify if the item list is valid
          itemsList.forEach((item) => {
            if(!typeFilterSet.has(item)) {
              navigate(`/products/notfound`);
            }
          });


          typeFilter = [];
          itemsList!.forEach( item => {
            typeFilter.push(item);
          });

          setType(typeFilter);

          console.log('itemsList: ', itemsList);

          let productOutput: product[] = [];

          subCatgList!.forEach( subCatg => {
            console.log("subCatg: ", subCatg);
            const itemsMap = subCategoryMap!.get(subCatg);

            console.log('itemsMap: ', itemsMap);

            itemsList!.forEach( item => {
              const prodMap = itemsMap!.get(item);
              console.log('prodMap: ', prodMap);
              if(prodMap != undefined) {
                productOutput = productOutput.concat(Array.from(prodMap.values()));
              }
            });
          });

          setProducts(productOutput);
          }

          scrollToTop.scrollTo(0 , 0);
        }
        else {
          navigate(`/products/notfound`);
        }
      } 
      
    }, [searchParams]);

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
            <Box
                component="nav"
                aria-label="filters"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        width: drawerWidth,
                        display: { xs: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}
                >
                  {drawer}
                </Drawer>
            </Box>

            <Box
                component="main"
                sx={{ 
                  flexGrow: 1,
                  marginBottom: 2
                }}
            >
              
              <Button size="small" onClick={() => handleDrawerToggle()} 
                style={{padding: 3, marginBottom: 5, backgroundColor: 'transparent', alignItems: 'left'}} 
                variant="text" sx={{ position: "fixed", top: extraSmallScreen ? marginTop - 70 :marginTop, 
                left: extraSmallScreen ? 1 : extraLargeScreen ? '11%' : 20, zIndex: 0 }}
              >
                <Typography style={extraSmallScreen ? 
                  {fontSize: '11px', color: '#626262', fontFamily: "'Roboto', 'Helvetica', sans-serif", letterSpacing: '0px'} : 
                  {fontSize: '12px', color: '#626262', fontFamily: "'Roboto', 'Helvetica', sans-serif", letterSpacing: '1px' }}>
                  <strong>Filters</strong>
                </Typography>
              </Button>

                
                <Grid container 
                  style={{marginTop: extraSmallScreen ? marginTop-40 : marginTop+50}}
                >
                  
                  {
                    products.map((item,index)=>{
                      return <Grid container item xs={6} sm={6} md={4} lg={3} key={index} justifyContent="center" alignItems="center">
                      <Card 
                        sx={{ borderRadius: 0, boxShadow: '0px 0px 0.75px gray' }}
                      onClick={ () => {
                        // navigate(`/productdetail?imgUrl=${item.imgurl}&imgName=${item.imgname}&imgDetails=${item.imgDetails}&imgPercentage=${item.imgPercentange}`);
                        navigate(`/productdetail?id=${item.imgId}`);
                      }}>
                        <CardMedia
                          component="img"
                          sx={{boxShadow: 'none'}}
                          image={item.imgurl}
                          alt="green iguana"
                        />
                        <CardContent 
                          style={{background: '#000000' }}
                        >
                          
                          <Typography noWrap  variant="body2" align="left" 
                          style={{ fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 : 16, 
                          fontFamily: 'Lucida Handwriting', fontWeight: 'Regular', 
                            color: '#a19b9b' 
                          }}
                          >
                          {item.imgname} 
                          </Typography>

                          <Typography noWrap  variant="body2" align="left" 
                          style={{ fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 : 16, 
                          fontFamily: 'Lucida Handwriting', 
                          color: '#a19b9b' 
                          }}
                          >
                          Wt: {item.imgDetails}
                          </Typography>

                          <Typography noWrap  variant="body2" align="left" 
                          style={{ fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 :  16, 
                          fontFamily: 'Lucida Handwriting', 
                          color: '#a19b9b' 
                          }}
                          >
                          Carat: {item.imgPercentage}
                          </Typography>
                          
                        </CardContent>
                      </Card>
                    </Grid>
                    })
                  }
                </Grid>
            </Box>
          </Box>
        </div>
    )
}

