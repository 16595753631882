

import * as React from 'react';
import {useDispatch} from "react-redux";
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import { footerAction } from '../../store';

export default function PageNotFoundComponent() { 

    const dispatch = useDispatch();
    dispatch(footerAction.unsetShowProducts());
  
    return (
        <Container style={{padding: '15%'}}>
            <Typography noWrap  variant="body2" align="center" style={{ fontSize: 28, fontFamily: 'Lucida Handwriting' }}>
                404 - Not found
            </Typography>
        </Container>
    );
};