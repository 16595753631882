import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IconButton, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { APPNAME } from '../../config/constant';
import ScrollableTabs from './menu/ScrollableTabs';
import NewScrollableTabs from './menu/NewScrollableTabs';

export default function NewHeader() { 

    const theme = useTheme();
    const smallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

   
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="secondary" style={{boxShadow: '0px 0px 0px'}}
                sx={{
                    ':hover': {
                        backgroundColor: 'secondary.main',
                    },
                    // below setting only keep the header fixed for products and productdetail page
                    // backgroundColor: window.location.pathname.includes('product') || window.location.pathname.includes('productdetail') ? 'secondary.main': anchorEl == null ? 'transparent' : 'secondary.main' 
                  }}
            >
                {
                    smallerScreen ? 
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={handleClick}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                                marginThreshold={0}
                                PaperProps={{
                                    style: {
                                        width: "100%",
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        transform: smallerScreen ? 'translateY(-1.75%)' : 'translateY(0.01%)',
                                        boxShadow: '0.5px 0.5px 0.5px gray'
                                    },
                                }}
                                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            >
                                <NewScrollableTabs handleClose={handleClose}/>
                            </Menu>
                        
                            <Button sx={{ flexGrow: 1 }} onClick={() => {
                                navigate('/', {replace:true})}}
                                style={{ backgroundColor: 'transparent' }}
                                disableRipple
                            >
                                <Typography  variant="h6" style={smallerScreen ? 
                                    {fontSize: '18px', color: '#626262', fontFamily: 'Lucida Handwriting', letterSpacing: '3px'} : 
                                    {fontSize: '22px', color: '#626262', fontFamily: 'Lucida Handwriting', letterSpacing: '4px' }}>
                                    <strong>{APPNAME}</strong>
                                </Typography>
                            </Button>

                            {/* Add IconButton to add same margin as the hamburger icon on the left to center the APPNAME */}
                            <IconButton
                                size="large"
                                sx={{ mr: 2 }}
                                disabled
                                style={{color: '#626262'}}
                            >
                            </IconButton>
                        </Toolbar>
                    
                        : 
                        <Box>
                            <Toolbar>
                                <Box sx={{flexGrow: 5}}></Box>
                                <Button sx={{flexGrow: 1}} disableRipple
                                    onClick={() => {navigate('/', {replace:true})}}
                                    style={{ backgroundColor: 'transparent' }}
                                >
                                    <Typography variant="h6" style={smallerScreen ? 
                                        {fontSize: '14px', color: '#626262', fontFamily: 'Lucida Handwriting', letterSpacing: '1px'} : 
                                        {fontSize: '22px', color: '#626262', fontFamily: 'Lucida Handwriting', letterSpacing: '4px' }}>
                                        <strong>{APPNAME}</strong>
                                    </Typography>
                                </Button>
                                <Box sx={{flexGrow: 5}}></Box>
                            </Toolbar>
                            <ScrollableTabs handleClose={handleClose}/>
                        </Box>
                }
            </AppBar>
        </Box>
    )
}