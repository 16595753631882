
import * as React from 'react';
import { useDispatch } from "react-redux";
import {  Card, CardMedia, Grid, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { footerAction } from '../../store';
import { CATEGORY_MAP, product  } from '../../config/configuration';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
  }

const marginTop = 140;  
const totalHeight = 0;

export default function ProductDetailsComponent(props: Props) {

    const theme = useTheme();
    const extraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const mediumScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [searchParams, setSearchParams] = useSearchParams()
    const [productDetails, setProductDetails] = React.useState<product>({} as product);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    dispatch(footerAction.unsetShowProducts());

    const getCategory = (letter: string) => {
        switch(letter){
            case 'w': 
                return "Women"
            default:
                return "invalid"
        } 
    }

    const getSubCategory = (letter: string) => {
        switch(letter){
            case 'g': 
                return "Gold"
            case 'd': 
                return "Diamond"
            default:
                return "invalid"
        } 
    }

    const getProductDetail = (category: string, subCategory: string, params: string[]) => {
        const ornamenttype = params[3]
        console.log("ornamenttype: ", ornamenttype)
        const productsMap = CATEGORY_MAP.get(category)!.get(subCategory)!.get(ornamenttype[0].toUpperCase() + ornamenttype.slice(1))
        console.log("productsMap: ", productsMap)
        if(productsMap != undefined) {
            let productDetail = productsMap.get(params.join("_"))
            console.log("productDetail: ", productDetail)
            if(productDetail != undefined) {
                setProductDetails(productDetail)
                return true
            }
        }
        return false
    }

    const validateQuery = (param: string) => {
        const params:string[] = param.split("_");
        if(params.length == 5) {
            console.log("params is length: ", params.length)
            console.log("params is: ", params)
            const category = getCategory(params[1])
            const subCategory = getSubCategory(params[2])
            console.log("category: ", category)
            console.log("subcategory: ", subCategory)
            if(category != "invalid" && subCategory != "invalid") {
                return getProductDetail(category, subCategory, params)
            }
        }
        return false
    }

    React.useEffect(() => {

        //validate the query params
        if (searchParams.get('id') == undefined || searchParams.get('id') == "" || !validateQuery(searchParams.get('id')!)) {
            navigate('/pagenotfound')
        }

        window.scrollTo(0, 0);
      }, []);

    return (

        <Box sx={{minHeight: totalHeight}}>

            <Grid container 
                style={{marginTop: extraSmallScreen ? marginTop-40 : marginTop+30, marginBottom: 30}}
            >
                <Grid container item xs={12} sm={6} justifyContent="flex-end" alignItems="center">
                     <Card sx={{  boxShadow: 'none'}}>
                        <CardMedia image={productDetails.imgurl} component="img"/>
                    </Card>
                </Grid>

                <Grid container item xs={12} sm={6} justifyContent="center" alignItems="flex-start" sx={{background: '#fafafa'}}>
                    <Box sx={{paddingTop: smallScreen ? 5 : 15, marginBottom: 10}}>
                        <Typography variant="h5" 
                            style={{
                                fontFamily: "'Roboto', 'Helvetica', sans-serif", letterSpacing: '1px', color: '#626262'
                            }}
                        >
                          <strong>Details</strong>
                        </Typography>

                        <Typography
                            noWrap  variant="body2" align="left" 
                            style={{ 
                                padding: 5, marginTop: 15,
                                fontSize: extraSmallScreen ? 12 : smallScreen ? 14 : mediumScreen ? 16 : 18, 
                            fontFamily: 'Lucida Handwriting', color: '#626262',
                            fontWeight: 'Regular' }}
                        >
                        Name: {productDetails.imgname}
                        </Typography>

                        <Typography noWrap  variant="body2" align="left" 
                        style={{ 
                            padding: 5,  
                            fontSize: extraSmallScreen ? 12 : smallScreen ? 14 : mediumScreen ? 16 : 18, 
                        fontFamily: 'Lucida Handwriting', fontWeight: 'Regular', color: '#626262'}}>
                        Wt: {productDetails.imgDetails}
                        </Typography>

                        <Typography noWrap  variant="body2" align="left" 
                        style={{
                            padding: 5,  
                            fontSize: extraSmallScreen ? 12 : smallScreen ? 14 : mediumScreen ? 16 :  18, 
                        fontFamily: 'Lucida Handwriting', fontWeight: 'Regular', color: '#626262' }}>
                        Carat: {productDetails.imgPercentage}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
         </Box>
    )
}

