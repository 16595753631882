import * as React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function FooterComponent() { 

    const theme = useTheme();
    const smallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <AppBar position='sticky' color="secondary" 
            style={{
                boxShadow: '0px 0px 0px gray', 
            }}
             >
                <Toolbar
                    style={{ marginTop: smallerScreen ? '1%' : '2%', marginBottom: smallerScreen ? '1%' : '2%'}}
                >
                    <Typography 
                        variant="body2" 
                        sx={{ flexGrow: 1, color: '#626262',
                        fontFamily: 'Lucida Handwriting'
                        }} 
                        
                        style={smallerScreen ? {fontSize: '11px'} : 
                        {fontSize: '14px', letterSpacing: '2px'}}
                    >
                        <div style={{margin: smallerScreen ? '3%' : '15px'}}>Copyright © 2023  New Choudhary Jewellers</div>
                        
                        <div style={{margin: smallerScreen ? '2%' : '15px'}}>Contact: +91 022 26123180  </div>

                        <div style={{margin: smallerScreen ? '3%' : '15px'}}>Email: newchoudharyjewellers@gmail.com</div>

                    </Typography>

                </Toolbar>
            </AppBar>
        </div>
    ) 
}