import * as React from 'react';
import '../../App.css';
import {useDispatch} from "react-redux";
import { Grid,  Card, CardMedia, CardActions, Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { footerAction } from '../../store';
import { WOMEN_SUB_CATEGORY_LIST, WOMEN_ITEM_LIST } from '../../config/configuration';

import photo1 from "../../../src/static/images/cards/main/photo1.jpg";
import photo2 from "../../../src/static/images/cards/main/photo2.jpg";
import photo3 from "../../../src/static/images/cards/main/photo3.jpg";
import photo6 from "../../../src/static/images/cards/main/photo6.jpg";
import photo8 from "../../../src/static/images/cards/main/photo8.jpg";
import photo9 from "../../../src/static/images/cards/main/photo9.jpg";
import ncj_bangle_0011 from "../../../src/static/images/cards/main/ncj_bangle_0011.jpg";
import ncj_bangle_0010 from "../../../src/static/images/cards/main/ncj_bangle_0010.jpg";
import ncj_bangle_0009 from "../../../src/static/images/cards/main/ncj_bangle_0009.jpg";
import ncj_pend_0006 from "../../../src/static/images/cards/main/ncj_pend_0006.jpg";
import ncj_pend_0005 from "../../../src/static/images/cards/main/ncj_pend_0005.jpg";
import ncj_pend_0004 from "../../../src/static/images/cards/main/ncj_pend_0004.jpg";
import ncj_pend_0003 from "../../../src/static/images/cards/main/ncj_pend_0003.jpg";
import ncj_pend_0002 from "../../../src/static/images/cards/main/ncj_pend_0002.jpg";
import ncj_pend_0001 from "../../../src/static/images/cards/main/ncj_pend_0001.jpg";
import ncj_set_0001 from "../../../src/static/images/cards/main/ncj_set_0001.jpg";
import ncj_necklace_0001 from "../../../src/static/images/cards/main/ncj_necklace_0001.jpg";
import gen_ai_necklace_main_04 from "../../../src/static/images/cards/main/gen_ai_necklace_main_04.png";
import gen_ai_necklace_main_wider_01 from "../../../src/static/images/cards/main/gen_ai_necklace_main_wider_01.png";

export default function HomeComponent() { 

    const dispatch = useDispatch();
    dispatch(footerAction.unsetShowProducts());

    const theme = useTheme();
    const extraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const navigate = useNavigate();

    function onFabClick(category: string, subcategory: string, item: string ) {

        if(subcategory.toLowerCase() === 'all') {
            subcategory = WOMEN_SUB_CATEGORY_LIST.join(",");
        }

        if(item.toLowerCase() === 'all') {
            item = WOMEN_ITEM_LIST.join(",");
        }

        let buildUrl = `?category=${category}&subcategory=${subcategory}&item=${item}`
        navigate(`/products${buildUrl}`);
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <Box style={{paddingTop: extraSmallScreen ? 40 : 70}}>
            <div style={{
                marginTop: extraSmallScreen ? "5%" : smallScreen ? "7%" : mediumScreen ? "5%" : "3%",
                background: "#000000", 
                justifyContent: "center", 
                display: "flex", 
                alignItems: 'center'
                }}>
                <Card sx={{ boxShadow: 'none', maxWidth: extraSmallScreen ? 450 : smallScreen ? 550: mediumScreen ? 650 : 780, background: "#000000"}}>
                    <CardMedia
                        component="img"
                        // image={ncj_necklace_0001}
                        // image={gen_ai_necklace_main_04}
                        image={gen_ai_necklace_main_wider_01}
                    />

                    <Button 
                        style={{ backgroundColor: 'transparent' }}
                        variant="text"
                        sx={{
                            bottom: extraSmallScreen ? 10 : smallScreen ? 20 : mediumScreen ? 20 : 20,
                            height: extraSmallScreen ? 50 : smallScreen ? 70 : mediumScreen ? 90 : 100
                        }}
                        onClick={() => onFabClick("Women", "Gold,Diamond", "Pendent,Bangle")}
                    >    
                        <Typography noWrap 
                            variant="h5" 
                            align="center" 
                            style={{ 
                                color: '#a19b9b',
                                // color: '#626262',
                                fontSize: extraSmallScreen ? 20 : smallScreen ? 26 : mediumScreen ? 28 : 28, 
                                // fontWeight: "bold",
                                // fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                fontFamily: "Lucida Handwriting",
                                textTransform: 'capitalize',
                                letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                            }}
                        >
                            Explore <i className={extraSmallScreen ? "arrowExtraSmall right" : smallScreen ? 
                            "arrowSmall right" : mediumScreen ? "arrowMedium right" : "arrow right"}></i>
                            <br/>
                            {/* <Typography style={{ 
                                // color: '#626262',
                                color: '#a19b9b',
                                fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 : 20, 
                                // fontWeight: "bold",
                                // fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                fontFamily: "Lucida Handwriting",
                                textTransform: 'lowercase',
                                letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                            }}>our collection</Typography> */}
                        </Typography>    
                        
                    </Button>
                </Card>
            </div>
            <div style={{ 
                // background: '#f8efee',
                 display:'flex', 
                 justifyContent:'center', 
                 minHeight: extraSmallScreen ? '40vh' : smallScreen ? '45vh': mediumScreen ? '50vh' : '60vh', 
                 alignItems: 'center'
                 }}>
                <Card sx={{
                    // background: '#f8efee',
                    boxShadow: 'none', 
                    maxWidth: extraSmallScreen ? 320 : smallScreen ? 520: 680,
                    background: "#FFFFFF"
                }}>
                    <Typography 
                        style={{
                            color: '#626262',
                            fontSize: extraSmallScreen ? 14 : smallScreen ? 16 : mediumScreen ? 18 : 20, 
                            fontFamily: "Lucida Handwriting",
                            letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                        }}>
                        {/* Discover a world of extraordinary beauty at NCJ. Immerse yourself in our captivating collection of handcrafted masterpieces, designed to adorn and enchant. Embrace the essence of timeless elegance and elevate your style with our exquisite jewellery. */}
                        At NCJ, we believe that every piece of jewellery tells a unique story, reflecting the individuality and refined taste of its wearer. Whether you seek a dazzling statement necklace to grace a special occasion or delicate earrings to enhance your everyday style, our meticulously curated selection offers a treasure trove of options to adorn yourself or gift to someone dear.
                    </Typography>
                </Card>
            </div>
            
            
            {/* <Card sx={{ boxShadow: 'none' }}>
                <CardMedia
                    component="img"
                    image={photo3}
                />

                <Button 
                    style={{ backgroundColor: 'transparent' }}
                    variant="text"
                    color="secondary"
                    sx={{
                        bottom: extraSmallScreen ? 140 : smallScreen ? 300 : mediumScreen ? 350 : 500,
                        left: extraSmallScreen ? 15 : mediumScreen ? 25 : 6
                    }}
                    onClick={() => onFabClick("Women", "Gold", "Pendent")}
                >    
                 <Typography noWrap 
                        variant="h5" 
                        align="center" 
                        style={{ 
                            color: '#FFFFFF',
                            fontSize: extraSmallScreen ? 7 : smallScreen ? 12 : mediumScreen ? 14 : 21, 
                            fontWeight: "bold",
                            fontFamily: "'Roboto', 'Helvetica', sans-serif",
                            textTransform: 'capitalize'
                        }}
                    >
                        For Bride  <i className={extraSmallScreen ? "arrowWhiteExtraSmall right" : smallScreen ? 
                        "arrowWhiteSmall right" : mediumScreen ? "arrowWhiteMedium right" : "arrowWhite right"}></i>
                    </Typography>    
                    
                </Button>

                <Button 
                    color="secondary"
                    style={{ backgroundColor: 'transparent' }}
                    variant="text"
                    sx={{
                        bottom: extraSmallScreen ? 140 : smallScreen ?  300 : mediumScreen ? 350 : 500,
                        left: extraSmallScreen ? 5 : mediumScreen ? 25 : 17
                    }}
                    onClick={() => onFabClick("Women", "Gold,Diamond", "Pendent")}
                >    
                 <Typography noWrap 
                        variant="h5" 
                        align="center" 
                        style={{ 
                            color: '#FFFFFF',
                            fontSize: extraSmallScreen ? 7 : smallScreen ? 12 : mediumScreen ? 14 : 21, 
                            fontWeight: "bold",
                            fontFamily: "'Roboto', 'Helvetica', sans-serif",
                            textTransform: 'capitalize'
                        }}
                    >
                        For Groom <i className={extraSmallScreen ? "arrowWhiteExtraSmall right" : smallScreen ? 
                        "arrowWhiteSmall right" : mediumScreen ? "arrowWhiteMedium right" : "arrowWhite right"}></i>
                    </Typography>    
                </Button>

            </Card> */}
            {
                extraSmallScreen ? 
                <div>
                <div style={{
                    marginTop: extraSmallScreen ? "0.5%" : smallScreen ? "7%" : mediumScreen ? "5%" : "4%",
                    background: "#000000", 
                    justifyContent: "center", 
                    display: "flex", 
                    alignItems: 'center'
                    }}>
                    <Card sx={{ boxShadow: 'none', maxWidth: extraSmallScreen ? 250 : smallScreen ? 320: 380, background: "#000000"}}>
                        <CardMedia
                            component="img"
                            image={ncj_pend_0004}
                        />
    
                        <Button 
                            style={{ backgroundColor: 'transparent' }}
                            variant="text"
                            sx={{
                                bottom: 30,
                                height: 70
                            }}
                            onClick={() => onFabClick("Women", "Gold,Diamond", "Pendent")}
                        >    
                            <Typography noWrap 
                                variant="h5" 
                                align="center" 
                                style={{ 
                                    color: '#a19b9b',
                                    // color: '#626262',
                                    fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 : 21, 
                                    // fontWeight: "bold",
                                    // fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                    fontFamily: "Lucida Handwriting",
                                    textTransform: 'capitalize',
                                    letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                                }}
                            >
                                checkout <i className={extraSmallScreen ? "arrowExtraSmall right" : smallScreen ? 
                                "arrowSmall right" : mediumScreen ? "arrowMedium right" : "arrow right"}></i>
                                <br/>
                                <Typography style={{ 
                                    // color: '#626262',
                                    color: '#a19b9b',
                                    fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 : 20, 
                                    // fontWeight: "bold",
                                    // fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                    fontFamily: "Lucida Handwriting",
                                    textTransform: 'lowercase',
                                    letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                                }}>our pendent collection</Typography>
                            </Typography>    
                            
                        </Button>
                    </Card>
                    </div>
                    <div style={{
                        marginTop: "0.5%",
                        background: "#000000", 
                        justifyContent: "center", 
                        display: "flex", 
                        alignItems: 'center'
                        }}>
                    <Card sx={{ boxShadow: 'none', maxWidth: extraSmallScreen ? 250 : smallScreen ? 320: 380, background: "#000000"}}>
                        <CardMedia
                            component="img"
                            image={ncj_bangle_0009}
                        />
    
                        <Button 
                            style={{ backgroundColor: 'transparent' }}
                            variant="text"
                            sx={{
                                bottom: 30,
                                height: 70
                            }}
                            onClick={() => onFabClick("Women", "Gold,Diamond", "Bangle")}
                        >    
                            <Typography noWrap 
                                variant="h5" 
                                align="center" 
                                style={{ 
                                    color: '#a19b9b',
                                    // color: '#626262',
                                    fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 : 21, 
                                    // fontWeight: "bold",
                                    // fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                    fontFamily: "Lucida Handwriting",
                                    textTransform: 'capitalize',
                                    letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                                }}
                            >
                                checkout <i className={extraSmallScreen ? "arrowExtraSmall right" : smallScreen ? 
                                "arrowSmall right" : mediumScreen ? "arrowMedium right" : "arrow right"}></i>
                                <br/>
                                <Typography style={{ 
                                    // color: '#626262',
                                    color: '#a19b9b',
                                    fontSize: extraSmallScreen ? 14 : smallScreen ? 14 : mediumScreen ? 16 : 20, 
                                    // fontWeight: "bold",
                                    // fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                    fontFamily: "Lucida Handwriting",
                                    textTransform: 'lowercase',
                                    letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                                }}>our bangles collection</Typography>
                            </Typography>    
                            
                        </Button>
                    </Card>
                </div> 
                </div>
                : 
                <Grid container spacing={0}>
                    <Grid item xs={6} md={6} >
                        <div style={{ 
                            padding: extraSmallScreen? '35px' : smallScreen? '55px' : mediumScreen? '90px' : '120px',
                            background: '#000000'
                            }}>
                            <CardMedia
                            sx={{ boxShadow: 'none' }}
                            component="img"
                            image={ncj_pend_0004}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6} style={{ justifyContent: "center", display: "flex", alignItems: 'center' }} >
                        {/* <CardMedia
                        sx={{ boxShadow: 'none' }}
                        component="img"
                        image={photo8}
                        /> */}
                        
                            <Button 
                                color="secondary"
                                style={{ backgroundColor: 'transparent' }}
                                variant="text"
                                onClick={() => onFabClick("Women", "Gold,Diamond", "Pendent")}
                            >    
                            <Typography noWrap 
                                    variant="h5" 
                                    style={{ 
                                        color: '#626262',
                                        fontSize: extraSmallScreen ? 10 : smallScreen ? 12 : mediumScreen ? 14 : 21, 
                                        // fontWeight: "bold",
                                        // fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                        fontFamily: "Lucida Handwriting",
                                        textTransform: 'capitalize',
                                        letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                                    }}
                                >
                                    checkout our pendent collection <i className={extraSmallScreen ? "arrowWhiteExtraSmall right" : smallScreen ? 
                                    "arrowWhiteSmall right" : mediumScreen ? "arrowWhiteMedium right" : "arrowWhite right"}></i>
                                </Typography>    
                            </Button>
                    </Grid>
                
                    <Grid item xs={6} md={6} style={{ justifyContent: "center", display: "flex", alignItems: 'center' }}>
                        {/* <CardMedia
                        sx={{ boxShadow: 'none' }}
                        component="img"
                        image={photo9}
                        /> */}

                            <Button 
                                color="secondary"
                                style={{ backgroundColor: 'transparent' }}
                                variant="text"
                                onClick={() => onFabClick("Women", "Gold,Diamond", "Bangle")}
                            >    
                            <Typography noWrap 
                                    variant="h5" 
                                    style={{ 
                                        color: '#626262',
                                        fontSize: extraSmallScreen ? 10 : smallScreen ? 12 : mediumScreen ? 14 : 21, 
                                        // fontWeight: "bold",
                                        //fontFamily: "'Roboto', 'Helvetica', sans-serif",
                                        fontFamily: "Lucida Handwriting",
                                        textTransform: 'capitalize',
                                        // marginTop: extraSmallScreen? '-35px' : smallScreen? '-55px' : mediumScreen? '-90px' : '-120px',
                                        letterSpacing: extraSmallScreen ? '0px' : smallScreen ? '1px' : '2px'
                                    }}
                                >
                                    checkout our bangles collection <i className={extraSmallScreen ? "arrowWhiteExtraSmall right" : smallScreen ? 
                                    "arrowWhiteSmall right" : mediumScreen ? "arrowWhiteMedium right" : "arrowWhite right"}></i>
                                </Typography>    
                            </Button>
                    </Grid>
                    <Grid item xs={6} md={ 6}>
                        <div style={{ 
                            // paddingLeft: extraSmallScreen? '35px' : smallScreen? '55px' : mediumScreen? '90px' : '120px', 
                            // paddingRight: extraSmallScreen? '35px' : smallScreen? '55px' : mediumScreen? '90px' : '120px', 
                            // paddingBottom: extraSmallScreen? '35px' : smallScreen? '55px' : mediumScreen? '90px' : '120px',
                            padding: extraSmallScreen? '35px' : smallScreen? '55px' : mediumScreen? '90px' : '120px',
                            background: '#000000'
                            }}>
                            <CardMedia
                            sx={{ boxShadow: 'none' }}
                            component="img"
                            image={ncj_bangle_0009}
                            />
                        </div>
                    </Grid>
                </Grid>
            }
        </Box>
    )
}