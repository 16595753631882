import { palette } from '@mui/material/node_modules/@mui/system';
import { createTheme } from '@mui/material/styles';


export const customTheme = createTheme({
    palette: {
        secondary: {
            main: '#ffffff'
        }
        // neutral: {
        //     main: '#ffffff'
        //     contrastText: '#fff'
        // }
    }
});

