import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import MenuForWomen from "./menuItem/MenuForWomen";
import MenuForMen from "./menuItem/MenuForMen";
import MenuForBride from './menuItem/MenuForBride';
import MenuForGroom from './menuItem/MenuForGroom';
import MenuForChild from './menuItem/MenuForChild';
import MenuForGift from './menuItem/MenuForGift';
import MenuForFestive from './menuItem/MenuForFestive';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
  
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2.5  }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
    handleClose: () => void;
}  

export default function ScrollableTabs(props: Props) {
  const [value, setValue] = React.useState(-1);

  const theme = useTheme();
  const scrollableTabs = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePopoverOpen = (newValue: number) => {
    setValue(newValue);
  };

  const handlePopoverClose = () => {
    setValue(-1);
    props.handleClose();
  };

  return (

    <Container >
        <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
          <Tabs
              onMouseLeave={handlePopoverClose}
              value={value == -1 ? false : value}
              variant={scrollableTabs ? "scrollable" : "fullWidth"}
              scrollButtons={scrollableTabs ? true : false}
              allowScrollButtonsMobile={scrollableTabs ? true : false}
              aria-label="scrollable tabs"
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}}
          >
              <Tab 
                onMouseEnter={() => handlePopoverOpen(0)}
                onClick={() => handlePopoverOpen(0)}
                sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Women" 
              />
              <Tab 
              onMouseEnter={() => handlePopoverOpen(1)}
              onClick={() => handlePopoverOpen(1)}
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Men" />
              <Tab 
              onMouseEnter={() => handlePopoverOpen(2)}
              onClick={() => handlePopoverOpen(2)}
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Bridal" />
              <Tab 
              onMouseEnter={() => handlePopoverOpen(3)}
              onClick={() => handlePopoverOpen(3)}
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Bridegroom" />
              <Tab 
              onMouseEnter={() => handlePopoverOpen(4)}
              onClick={() => handlePopoverOpen(4)}
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Kids" />
              <Tab 
              onMouseEnter={() => handlePopoverOpen(5)}
              onClick={() => handlePopoverOpen(5)}
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Gift" />
              <Tab 
              onMouseEnter={() => handlePopoverOpen(6)}
              onClick={() => handlePopoverOpen(6)}
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Festives" />
          </Tabs>
        </Box>

        <Box 
        onMouseEnter={() => handlePopoverOpen(value)}
        onMouseLeave={handlePopoverClose}
        sx={{ width: '100%', bgcolor: 'background.paper'}}>
        <TabPanel value={value} index={0}>
          <MenuForWomen handleClose={handlePopoverClose}></MenuForWomen>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <MenuForMen handleClose={handlePopoverClose}></MenuForMen>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <MenuForBride handleClose={handlePopoverClose}></MenuForBride>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <MenuForGroom handleClose={handlePopoverClose}></MenuForGroom>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <MenuForChild handleClose={handlePopoverClose}></MenuForChild>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <MenuForGift handleClose={handlePopoverClose}></MenuForGift>
        </TabPanel>

        <TabPanel value={value} index={6}>
          <MenuForFestive handleClose={handlePopoverClose}></MenuForFestive>
        </TabPanel>
        </Box>
    
      {/* </Box> */}
    </Container>
  );
}