import * as React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography, Backdrop } from '@mui/material';

interface Props {
    handleClose: () => void;
 }

export default function MenuForBride(props:Props) {
    const theme = useTheme();
    const gridDirColumn = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
      <div style={{ position: "relative"}}>
        <Backdrop
            style={{ position: "absolute" }}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={props.handleClose}
        >
            <Typography 
                noWrap  
                // variant="h5" 
                align="center" 
                // style={{ fontSize: extraSmallScreen ? 10 : smallScreen ? 12 : mediumScreen ? 14 : 16, 
                // fontFamily: 'Lucida Handwriting', fontWeight: 'Regular', color: '#4f4e4e' }}
                style={{
                    fontFamily: 'Lucida Handwriting',
                    fontSize: '26px'
                }}
                >
                Coming Soon 
            </Typography>
        </Backdrop>
        <Grid container paddingLeft={gridDirColumn ? 0 : 5} paddingRight={gridDirColumn ? 0 : 5}>
            <Grid item  md={4} xs={6} sm={3}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography  fontSize={16}><strong>All</strong></Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Necklace</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Earing</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Bangles</Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center', paddingBottom: gridDirColumn ? 5 : 0 }} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Chains</Typography></MenuItem> */}
            </Grid>
            <Grid item  md={4} xs={6} sm={3} sx={{alignItems: 'center'}}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography fontSize={16}><strong>Gold</strong></Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Necklace</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Earing</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Bangles</Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Chains</Typography></MenuItem> */}
             </Grid>
            
            <Grid item  md={4} xs={6} sm={3}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography fontSize={16}><strong>Diamond</strong></Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Necklace</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Earing</Typography></MenuItem>
            </Grid>
            {/* <Grid item  md={3} xs={6} sm={3}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography fontSize={16}><strong>Silver</strong></Typography></MenuItem> */}
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Necklace</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Earing</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Bangles</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Chains</Typography></MenuItem> */}
            {/* </Grid> */}
        </Grid>
      </div>
    );
}