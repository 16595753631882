import * as React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { WOMEN_SUB_CATEGORY_LIST, WOMEN_ITEM_LIST } from '../../../../config/configuration';

interface Props {
    handleClose: () => void;
 }

// const MenuItem = withStyles({
//      root: {
//          justifyContent: "flex-end"
//      }
//  })(MenuItem)



export default function MenuForWomen(props: Props) {
    const theme = useTheme();
    const gridDirColumn = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    function onMenuClick(category: string, subcategory: string, item: string ) {

        if(subcategory.toLowerCase() === 'all') {
            subcategory = WOMEN_SUB_CATEGORY_LIST.join(",");
        }

        if(item.toLowerCase() === 'all') {
            item = WOMEN_ITEM_LIST.join(",");
        }

        let buildUrl = `?category=${category}&subcategory=${subcategory}&item=${item}`
        navigate(`/products${buildUrl}`);
        props.handleClose();
    }

    return (
      <div>
        <Grid container paddingLeft={gridDirColumn ? 0 : 5} paddingRight={gridDirColumn ? 0 : 5}>
            <Grid item  md={4} xs={6} sm={3}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "all", "all")}><Typography  fontSize={16}><strong>All</strong></Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem> */}
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "all", "Pendent")}><Typography variant={gridDirColumn ? "caption" : "body2"}>Pendent</Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Earing</Typography></MenuItem> */}
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "all", "Bangle")}><Typography variant={gridDirColumn ? "caption" : "body2"}>Bangles</Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center', paddingBottom: gridDirColumn ? 5 : 0 }} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Chains</Typography></MenuItem> */}
            </Grid>
            <Grid item  md={4} xs={6} sm={3} sx={{alignItems: 'center'}}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "Gold", "all")}><Typography fontSize={16}><strong>Gold</strong></Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "Gold,Diamond", "Ring")}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem> */}
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Necklace</Typography></MenuItem> */}
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "Gold", "Pendent")}><Typography variant={gridDirColumn ? "caption" : "body2"}>Pendent</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "Gold", "Bangle")}><Typography variant={gridDirColumn ? "caption" : "body2"}>Bangles</Typography></MenuItem>
                {/* <MenuItem */}
                {/* sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Chains</Typography></MenuItem> */}
            </Grid>
            
            <Grid item  md={4} xs={6} sm={3}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "Diamond", "all")}><Typography fontSize={16}><strong>Diamond</strong></Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem> */}
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "Diamond", "Pendent")}><Typography variant={gridDirColumn ? "caption" : "body2"}>Pendent</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={() => onMenuClick("Women", "Diamond", "Bangle")}><Typography variant={gridDirColumn ? "caption" : "body2"}>Bangles</Typography></MenuItem>
                {/* <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Earing</Typography></MenuItem> */}
            </Grid>
            {/* <Grid item  md={3} xs={6} sm={3}>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography fontSize={16}><strong>Silver</strong></Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Rings</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Necklace</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Earing</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Bangles</Typography></MenuItem>
                <MenuItem sx={{justifyContent: 'center'}} dense={true} onClick={props.handleClose}><Typography variant={gridDirColumn ? "caption" : "body2"}>Chains</Typography></MenuItem>
            </Grid> */}
        </Grid>
      </div>
    );
}