import {createSlice, configureStore} from "@reduxjs/toolkit";

export interface RootState {
    showProducts : {
        value: boolean
    }
}

const footerSlice = createSlice({
    name: 'footer',
    initialState: { showProducts: false },
    reducers: {
        setShowProducts(state) {
            state.showProducts = true;
        },
        unsetShowProducts(state) {
            state.showProducts = false;
        }
    }
})

const store = configureStore({
    reducer: footerSlice.reducer
});

export const footerAction = footerSlice.actions;
export default store;