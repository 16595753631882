import rings1 from "../static/images/cards/main/rings1.jpg";
import rings2 from "../static/images/cards/main/rings2.jpg";
import rings3 from "../static/images/cards/main/rings3.jpg";
import rings4 from "../static/images/cards/main/rings4.jpg";
import rings5 from "../static/images/cards/main/rings5.jpg";
import rings6 from "../static/images/cards/main/rings6.jpg";
import rings7 from "../static/images/cards/main/rings7.jpg";
import rings8 from "../static/images/cards/main/rings8.jpg";
import rings9 from "../static/images/cards/main/rings9.jpg";
import rings10 from "../static/images/cards/main/rings10.jpg";
import rings11 from "../static/images/cards/main/rings11.jpg";
import khushi1 from "../static/images/cards/main/khushi1.jpg";

import ncj_pendent_0001 from "../static/images/cards/main/ncj_pend_0001.jpg";
import ncj_pendent_0002 from "../static/images/cards/main/ncj_pend_0002.jpg";
import ncj_pendent_0003 from "../static/images/cards/main/ncj_pend_0003.jpg";
import ncj_pendent_0004 from "../static/images/cards/main/ncj_pend_0004.jpg";
import ncj_pendent_0005 from "../static/images/cards/main/ncj_pend_0005.jpg";
import ncj_pendent_0006 from "../static/images/cards/main/ncj_pend_0006.jpg";

import ncj_bangle_0001 from "../static/images/cards/main/ncj_bangle_0001.jpg";
import ncj_bangle_0002 from "../static/images/cards/main/ncj_bangle_0002.jpg";
import ncj_bangle_0003 from "../static/images/cards/main/ncj_bangle_0003.jpg";
import ncj_bangle_0004 from "../static/images/cards/main/ncj_bangle_0004.jpg";
import ncj_bangle_0005 from "../static/images/cards/main/ncj_bangle_0005.jpg";
import ncj_bangle_0006 from "../static/images/cards/main/ncj_bangle_0006.jpg";
import ncj_bangle_0007 from "../static/images/cards/main/ncj_bangle_0007.jpg";
import ncj_bangle_0008 from "../static/images/cards/main/ncj_bangle_0008.jpg";
import ncj_bangle_0009 from "../static/images/cards/main/ncj_bangle_0009.jpg";
import ncj_bangle_0010 from "../static/images/cards/main/ncj_bangle_0010.jpg";
import ncj_bangle_0011 from "../static/images/cards/main/ncj_bangle_0011.jpg";

export enum CATEGORY {
    Women = "Women",
    Men = "Men",
    Bride = "Bride",
    BrideGroom = "BrideGroom",
    Kids = "Kids",
    Gift = "Gift",
    Festive = "Festive"
}

export enum WOMEN_SUB_CATEGORY {
    Gold = "Gold",
    Diamond = "Diamond",
    // Silver = "Silver"
}

export enum WOMEN_ITEMS {
    // Ring = "Ring",
    // Chain = "Chain",
    // Necklace = "Necklace",
    // Earing = "Earing",
    Bangles = "Bangle",
    Pendent = "Pendent"
}

export const WOMEN_SUB_CATEGORY_LIST = [
    WOMEN_SUB_CATEGORY.Gold,
    WOMEN_SUB_CATEGORY.Diamond,
    // WOMEN_SUB_CATEGORY.Silver
]

export const WOMEN_ITEM_LIST = [
    WOMEN_ITEMS.Bangles,
    // WOMEN_ITEMS.Chain,
    // WOMEN_ITEMS.Earing,
    // WOMEN_ITEMS.Necklace,
    // WOMEN_ITEMS.Ring,
    WOMEN_ITEMS.Pendent
]

export interface product {
    imgurl: string,
    imgId: string,
    imgname: string,
    imgDescription?: string,
    imgDetails?: string,
    imgPercentage?: string
}

// export const WOMEN_GOLD_RINGS: product[] = [
//     {imgurl: rings1, imgname: 'Image1 Diamond Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings7, imgname: 'Image2 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings5, imgname: 'Image3 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings6, imgname: 'Image4 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: khushi1, imgname: 'Image4 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
// ]

// export const WOMEN_SILVER_RINGS: product[] = [
//     {imgurl: rings8, imgname: 'Image1 Diamond Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings9, imgname: 'Image2 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings10, imgname: 'Image3 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings11, imgname: 'Image4 Gold Ring', imgPercentange: '18', imgDetails: '10gms'}
// ]

// export const WOMEN_DIAMOND_RINGS: product[] = [
//     {imgurl: rings6, imgname: 'Image1 Diamond Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings5, imgname: 'Image2 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings7, imgname: 'Image3 Gold Ring', imgPercentange: '18', imgDetails: '10gms'},
//     {imgurl: rings8, imgname: 'Image4 Gold Ring', imgPercentange: '18', imgDetails: '10gms'}
// ]

export const WOMEN_GOLD_BANGLES= new Map<string, product>([
    ["ncj_w_g_bangle_0002", {imgurl: ncj_bangle_0002, imgId: "ncj_w_g_bangle_0002", imgname: 'Gold Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_g_bangle_0004", {imgurl: ncj_bangle_0004, imgId: "ncj_w_g_bangle_0004", imgname: 'Gold Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_g_bangle_0006", {imgurl: ncj_bangle_0006, imgId: "ncj_w_g_bangle_0006", imgname: 'Gold Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_g_bangle_0007", {imgurl: ncj_bangle_0007, imgId: "ncj_w_g_bangle_0007", imgname: 'Gold Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_g_bangle_0008", {imgurl: ncj_bangle_0008, imgId: "ncj_w_g_bangle_0008", imgname: 'Gold Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    
])

export const WOMEN_DIAMOND_BANGLES= new Map<string, product>([
    ["ncj_w_d_bangle_0001", {imgurl: ncj_bangle_0001, imgId: "ncj_w_d_bangle_0001", imgname: 'Diamond Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_d_bangle_0003", {imgurl: ncj_bangle_0003, imgId: "ncj_w_d_bangle_0003", imgname: 'Diamond Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_d_bangle_0005", {imgurl: ncj_bangle_0005, imgId: "ncj_w_d_bangle_0005", imgname: 'Diamond Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_d_bangle_0009", {imgurl: ncj_bangle_0009, imgId: "ncj_w_d_bangle_0009", imgname: 'Diamond Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_d_bangle_0010", {imgurl: ncj_bangle_0010, imgId: "ncj_w_d_bangle_0010", imgname: 'Diamond Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
    ["ncj_w_d_bangle_0011", {imgurl: ncj_bangle_0011, imgId: "ncj_w_d_bangle_0011", imgname: 'Diamond Bangles', imgPercentage: '22k', imgDetails: '>18gms'}],
])

// export const WOMEN_GOLD_CHAIN: product[] = [
//     {imgurl: '', imgname: '', imgId: ''},
//     {imgurl: '', imgname: '', imgId: ''},
//     {imgurl: '', imgname: '', imgId: ''}
// ]

export const WOMEN_GOLD_PENDENT = new Map<string, product>([
    ["ncj_w_g_pendent_0005", {imgurl: ncj_pendent_0005, imgname: 'Leaf Gold Pendent', imgPercentage: '22k', imgDetails: '10gms', imgId: 'ncj_w_g_pendent_0005'}],
    ["ncj_w_g_pendent_0006", {imgurl: ncj_pendent_0006, imgname: 'Circle Gold Pendent', imgPercentage: '22k', imgDetails: '10gms', imgId: 'ncj_w_g_pendent_0006'}],
])

export const WOMEN_DIAMOND_PENDENT = new Map<string, product>([
    ["ncj_w_d_pendent_0001", {imgurl: ncj_pendent_0001, imgId: "ncj_w_d_pendent_0001", imgname: 'Peacock Gold Diamond Pendent', imgPercentage: '22k', imgDetails: '8gms'}],
    ["ncj_w_d_pendent_0002", {imgurl: ncj_pendent_0002, imgId: "ncj_w_d_pendent_0002", imgname: 'Heart Diamond studded Pendent', imgPercentage: '22k', imgDetails: '10gms'}],
    ["ncj_w_d_pendent_0003", {imgurl: ncj_pendent_0003, imgId: "ncj_w_d_pendent_0003", imgname: 'Jewel Diamond Pendent', imgPercentage: '18k', imgDetails: '12gms'}],
    ["ncj_w_d_pendent_0004", {imgurl: ncj_pendent_0004, imgId: "ncj_w_d_pendent_0004", imgname: 'Earing shared Diamond Pendent', imgPercentage: '18k', imgDetails: '11.5gms'}],
])

export const WOMEN_GOLD_TYPE = new Map<string, Map<string, product>>([
    // [WOMEN_ITEMS.Ring, WOMEN_GOLD_RINGS],
    [WOMEN_ITEMS.Bangles, WOMEN_GOLD_BANGLES],
    [WOMEN_ITEMS.Pendent, WOMEN_GOLD_PENDENT]
]);

// export const WOMEN_SILVER_TYPE = new Map<string, product[]>([
//     [WOMEN_ITEMS.Ring, WOMEN_SILVER_RINGS],
//     [WOMEN_ITEMS.Necklace, WOMEN_GOLD_NECKLACE]
// ]);

export const WOMEN_DIAMOND_TYPE = new Map<string, Map<string,product>>([
    [WOMEN_ITEMS.Bangles, WOMEN_DIAMOND_BANGLES],
    [WOMEN_ITEMS.Pendent, WOMEN_DIAMOND_PENDENT]
]);

export const WOMEN_SUB_CATEGORY_MAP = new Map<string, Map<string, Map<string,product>>>([
    [WOMEN_SUB_CATEGORY.Gold, WOMEN_GOLD_TYPE],
    // [WOMEN_SUB_CATEGORY.Silver, WOMEN_SILVER_TYPE],
    [WOMEN_SUB_CATEGORY.Diamond, WOMEN_DIAMOND_TYPE]
]);

export const CATEGORY_MAP = new Map<string, Map<string, Map<string, Map<string,product>>>>([
    [CATEGORY.Women, WOMEN_SUB_CATEGORY_MAP],
    [CATEGORY.Men, WOMEN_SUB_CATEGORY_MAP],
    [CATEGORY.Bride, WOMEN_SUB_CATEGORY_MAP],
    [CATEGORY.BrideGroom, WOMEN_SUB_CATEGORY_MAP],
    [CATEGORY.Kids, WOMEN_SUB_CATEGORY_MAP],
    [CATEGORY.Gift, WOMEN_SUB_CATEGORY_MAP],
    [CATEGORY.Festive, WOMEN_SUB_CATEGORY_MAP]
]);

