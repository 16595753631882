import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import MenuForWomen from "./menuItem/MenuForWomen";
import MenuForMen from "./menuItem/MenuForMen";
import MenuForBride from './menuItem/MenuForBride';
import MenuForGroom from './menuItem/MenuForGroom';
import MenuForChild from './menuItem/MenuForChild';
import MenuForGift from './menuItem/MenuForGift';
import MenuForFestive from './menuItem/MenuForFestive';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
  
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2.5  }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
    handleClose: () => void;
}  

export default function ScrollableTabs(props: Props) {
  const [value, setValue] = React.useState(0);

  const theme = useTheme();
  const scrollableTabs = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (

    <Container >
        <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
          <Tabs
              value={value}
              onChange={handleChange}
              variant={scrollableTabs ? "scrollable" : "fullWidth"}
              scrollButtons={scrollableTabs ? true : false}
              allowScrollButtonsMobile={scrollableTabs ? true : false}
              aria-label="scrollable tabs"
              sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}}
          >
              <Tab sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Women" />
              <Tab sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Men" />
              <Tab sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Bridal" />
              <Tab sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Bridegroom" />
              <Tab sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Kids" />
              <Tab sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Gift" />
              <Tab sx={scrollableTabs ? { minHeight: '30px', maxHeight: '30px'} : {}} label="Festives" />
          </Tabs>
        </Box>

        <Box sx={{ width: '100%', bgcolor: 'background.paper'}}>
          <TabPanel value={value} index={0}>
            <MenuForWomen handleClose={props.handleClose}></MenuForWomen>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <MenuForMen handleClose={props.handleClose}></MenuForMen>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <MenuForBride handleClose={props.handleClose}></MenuForBride>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <MenuForGroom handleClose={props.handleClose}></MenuForGroom>
          </TabPanel>

          <TabPanel value={value} index={4}>
            <MenuForChild handleClose={props.handleClose}></MenuForChild>
          </TabPanel>

          <TabPanel value={value} index={5}>
            <MenuForGift handleClose={props.handleClose}></MenuForGift>
          </TabPanel>

          <TabPanel value={value} index={6}>
            <MenuForFestive handleClose={props.handleClose}></MenuForFestive>
          </TabPanel>
        </Box>
    </Container>
  );
}