import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from "history";

import Footer from './component/footer/Footer';
import { customTheme } from './theme';
import ProductDetailsComponent from './component/body/ProductDetails';
import PageNotFoundComponent from './component/body/PageNotFound';
import OverviewComponent from './component/body/Overview';
import NewHeader from './component/header/NewHeader';
import Home from './component/body/Home';
import ComingSoon from './component/body/ComingSoon'; 
import ComingSoonHeader from './component/header/ComingSoonHeader';

function App() {

  const theme = useTheme();
  const history = createBrowserHistory();

  return (
    <ThemeProvider theme={customTheme}>
      <div className='App'>
        <NewHeader/>
        {/* <ComingSoonHeader /> */}

        {/* <Box sx={{ paddingX: {xl : '10%'}}}> */}
        <Box sx={{ paddingX: {xl : '10%'}}}>
          <Routes>
            {/* <Route path="/" element={<ComingSoon/>} /> */}
            <Route path="/" element={<Home/>} />
            <Route path="/products" element={<OverviewComponent />} />
            <Route path="/productdetail" element={<ProductDetailsComponent />} />
            <Route path="*" element={<PageNotFoundComponent />} />
          </Routes>
        </Box>

        <Footer/>
      </div>
    </ThemeProvider>
  );
}

export default App;
